import React from 'react';

import Icon from '@components/Icon';
import Link from '@components/Link';
import Title from '@components/Title';

import { useTranslation } from '@external/react-i18next';

const MyDonations: React.FC = () => {
  const { t, i18n } = useTranslation();

  let contributionFormHref = `/document/contribution-form-rotary-foundation`;
  const recognitionFormHref = `/document/paul-harris-fellow-recognition-transfer-request-form`;
  const indiaPanForm = '/my-donations/government-id/ind';
  const italyFiscalCodeForm = '/my-donations/government-id/ita';
  const koreaRRNForm = '/my-donations/government-id/kor';

  if (i18n.language.toLowerCase() === 'ja') {
    contributionFormHref = `/document/piif-contribution-form-rotarians-clubs`;
  }

  const links = [
    {
      title: t(
        'online-giving.my-donations-form.my-reports.donor-history-report',
        'DONOR HISTORY REPORT'
      ),
      to: `/secure/35006`,
      key: 'donor-history-report',
    },
    {
      title: t(
        'online-giving.my-donations-form.my-reports.individual-form',
        'INDIVIDUAL CONTRIBUTION FORM'
      ),
      to: contributionFormHref,
      key: 'individual-contribution-form',
    },
    {
      title: t(
        'online-giving.my-donations-form.my-reports.paul-harris-request',
        'PAUL HARRIS FELLOW RECOGNITION AND TRANSFER REQUEST'
      ),
      to: recognitionFormHref,
      key: 'paul-harris-fellow',
    },
  ];

  const ginAppLinks = [
    {
      title: t(
        'online-giving.my-donations-form.pan-page-link',
        'Add your Indian Permanent Account Number (PAN)'
      ),
      to: indiaPanForm,
      key: 'ind',
    },
    {
      title: t(
        'online-giving.my-donations-form.fiscal-code-page-link',
        'Add your Italian Fiscal Code'
      ),
      to: italyFiscalCodeForm,
      key: 'ita',
    },
    {
      title: t(
        'online-giving.my-donations-form.rrn-page-link',
        'Add your Korean Resident Registration Number (RRN)'
      ),
      to: koreaRRNForm,
      key: 'kor',
    },
  ];
  return (
    <div>
      <Title>
        {t('online-giving.my-donations-form.title', 'My Donations')}
      </Title>
      <p className="text-xs text-black mb-8 mt-20 mr-3">
        {t(
          'online-giving.my-donations-form.subheading',
          'Access your contribution history, commitments, and recognition achievements. You can also update your online Rotary Direct recurring gift arrangements.'
        )}
      </p>
      <div className="mb-6 font-medium text-lg-22 text-black">
        {t(
          'online-giving.my-donations-form.my-reports.subheading',
          'My Reports & Forms'
        )}
      </div>
      {links.map(link => (
        <div className="mb-4" key={link.key}>
          <Link
            className="link-styles-off text-bright-blue-600 text-xs font-bold active:text-bright-blue-600"
            to={link.to}
            target="_blank"
            useExternalLink
          >
            {link.title}
          </Link>
        </div>
      ))}
      <hr className="mb-5 mt-5 border-t-2 border-b border-gray-300" />
      <div className="mb-6 font-medium text-lg-22 text-black">
        {t(
          'online-giving.my-donations-form.my-reports.gin-app-heading',
          'My Government Identification Number (India, Italy & Korea Only)'
        )}
      </div>
      <div className="mb-4">
        {ginAppLinks.map(link => (
          <div className="mb-4" key={link.key}>
            <Link
              className="link-styles-off text-bright-blue-600 text-xs font-bold active:text-bright-blue-600"
              to={link.to}
              target="_blank"
              useExternalLink
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
      <div className="pt-48">
        <Icon
          name="squares/secure"
          color="white"
          size="20"
          className="m-auto block"
        />
        <p className="text-center m-5">
          {t(
            'online-giving.my-donations.data-safety.india-pan',
            'Your data is safe.'
          )}
        </p>
        <hr className="border-black" />
        <p className="mt-5">
          {t(
            'online-giving.my-donations.privacy-policy.india-pan',
            'Your privacy is important to Rotary. The personal data you share with Rotary will only be used for official Rotary business. This means that the personal data you provide will primarily be used for financial processing, supporting The Rotary Foundation, communicating key organizational messages, and responding to your inquiries. Personal data collected on this form is subject to <a href="https://my.rotary.org/en/privacy-policy" target="_blank">Rotary’s Privacy Policy</a>.'
          )}
        </p>
      </div>
    </div>
  );
};

export default MyDonations;
